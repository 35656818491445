<template>
  <HeaderNew
    logoUrl="https://legkovrace.ru/"
    :theme="theme"
    :user="user"
    :state="state"
    :logoImg="themeImages.logo"
    :logoAlt="themeName"
    logoWidth="29px"
    :loginUrl="`${themeUrls.Main}/login`"
    :menu="menu"
    :mobileMenu="menu"
    :profileMenu="profileMenu"
    :live="isLive"
    :liveUrl="isLive ? themeUrls.Live : ''"
    :locale="currentLang"
    :logoutUrl="`/Auth/LogOff`"
    logoutText="Выйти"
    :isFlagsLang="false"
    align="center"
  ></HeaderNew>
</template>

<script>
import { mapGetters } from 'vuex';
import eventsApi from '@/services/events-api';
import HeaderNew from '../HeaderNew/Header.vue';

export default {
  name: 'LegkovRaceHeader',
  components: {
    HeaderNew,
  },

  data() {
    return {
      menu: [
        {
          id: 1,
          text: 'События',
          href: `${this.themeUrls.Main}/events`,
          icon: 'calendar',
          open: false,
          itemsOnlyMobile: true,
          items: [
            {
              text: 'Предстоящие',
              href: `${this.themeUrls.Main}/events`,
            },
            {
              text: 'Прошедшие',
              href: this.themeUrls.Results,
            },
          ],
        },
        {
          id: 2,
          text: 'Результаты',
          href: this.themeUrls.Results,
          icon: '1st-place-medal',
        },
      ],
      profileMenu: [
        {
          name: 'Профиль',
          icon: 'user',
          link: `${this.themeUrls.Main}/Account`,
        },
        {
          name: 'Регистрация',
          icon: 'badge',
          link: `${this.themeUrls.Main}/Account/RegistrationList`,
        },
        {
          name: 'Заказы',
          icon: 'shopping-bag',
          link: `${this.themeUrls.Main}/Account/OrderList`,
        },
        {
          isDivider: true,
          hidden: !window.userInfo || (!window.userInfo.IsAdmin && !window.userInfo.IsBeneficiary),
        },
        {
          name: 'Организаторам',
          icon: 'caret-right',
          link: window.userInfo && window.userInfo.AdminUrl,
          hidden: !window.userInfo || (!window.userInfo.IsAdmin && !window.userInfo.IsBeneficiary),
        },
        {
          name: 'Счета',
          icon: 'caret-right',
          link: `${this.themeUrls.Main}/Account/Bills`,
          hidden: !window.userInfo || !window.userInfo.IsAdmin,
        },
        {
          name: 'Кэш',
          icon: 'caret-right',
          link: `${this.themeUrls.Main}/CacheAdmin`,
          hidden: !window.userInfo || !window.userInfo.IsAdmin,
        },
      ],
      isLive: false,
      currentLang: 'ru',
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeImages', 'themeName', 'themeSettings']),
    ...mapGetters('user', ['user', 'isAuthorized']),
    state() {
      if (this.isAuthorized) {
        return 'authorized';
      }
      return 'unauthorized';
    },
  },
  methods: {
    async getLiveCount() {
      const result = await eventsApi.getLiveCount();
      if (result.data > 0) {
        this.isLive = true;
      }
    },
  },
  mounted() {
    this.getLiveCount();
  },
  beforeCreate() {
    this.themeUrls = this.$store.getters['settings/themeUrls'];
  },
};
</script>
